<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:body>
                <b-overlay :show="loading">
                    <b-row>
                    <b-col lg="12" sm="12">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                            <ValidationProvider name="FAQ Name (En)" vid="faq_name" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="faq_name"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('elearning_config.faq_name_en')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                  id="faq_name"
                                  v-model="faqSetupData.faq_name"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="FAQ Name (Bn)" vid="faq_name_bn" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="faq_name_bn"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('elearning_config.faq_name_bn')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                  id="faq_name_bn"
                                  v-model="faqSetupData.faq_name_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Description (En)"  vid="description" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="description"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_config.description_en')}}
                                    </template>
                                    <b-form-textarea
                                        rows="2"
                                        id="description"
                                        v-model="faqSetupData.description"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Description (Bn)"  vid="description_bn" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="description_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_config.description_bn')}}
                                    </template>
                                    <b-form-textarea
                                        rows="2"
                                        id="description_bn"
                                        v-model="faqSetupData.description_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                            </div>
                        </b-form>
                        </ValidationObserver>
                    </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { faqSetupStore, faqSetupUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getfaqSetupData()
      this.faqSetupData = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      faqSetupData: {
        id: '',
        faq_name: '',
        faq_name_bn: '',
        description: '',
        description_bn: '',
        status: 1
      }
    }
  },
  methods: {
    getfaqSetupData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async createData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.faqSetupData.id) {
        result = await RestApi.putData(trainingElearningServiceBaseUrl, `${faqSetupUpdate}/${this.id}`, this.faqSetupData)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, faqSetupStore, this.faqSetupData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('elearning_config.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    }
  }
}
</script>
